<template>
  <div>
    <NuxtLink
      to="/profile"
      class="relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"
    >
      <span class="mr-2 text-6 i-custom:document text-firmennyy"></span>
      <p>Мои заказы</p>
      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">{{ orderAmount }}</span>
    </NuxtLink>
    <NuxtLink
      to="/profile/reviews"
      class="relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"
    >
      <span class="mr-2 text-6 i-custom:star-outline-2 text-firmennyy"></span>
      <p>Мои отзывы</p>
      <!--      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">15</span>-->
    </NuxtLink>
    <NuxtLink
      to="/profile/info"
      class="relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"
    >
      <span class="mr-2 text-6 i-custom:list-box-sharp text-firmennyy"></span>
      <p>Мои данные</p>
      <!-- <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors"></span> -->
    </NuxtLink>
    <NuxtLink
      to="/profile/addreses"
      class="relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"
    >
      <span class="mr-2 text-6 i-custom:pin text-firmennyy"></span>
      <p>Мои адреса</p>
      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">{{ currentUser?.addresses?.length || 0 }}</span>
    </NuxtLink>
    <!--    <NuxtLink-->
    <!--      to="/"-->
    <!--      class="relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"-->
    <!--    >-->
    <!--      <span class="mr-2 text-6 i-custom:add-layer text-firmennyy"></span>-->
    <!--      <p>Бонусы</p>-->
    <!--      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">2560</span>-->
    <!--    </NuxtLink>-->
    <button
      class="w-full relative group/lkdropdown flex items-center gap-2 px-2.5 py-4 text-#455862 text-4.5 font-500 leading-0.8 -tracking-0.01em rounded-2.5 transition-colors hover:text-firmennyy hover:bg-white"
      @click="logOutHandler"
    >
      <span class="mr-2 text-6 i-custom:x-circle?mask text-seryy/50 group-hover/lkdropdown:text-firmennyy transition-colors"></span>
      <p>Выйти</p>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useUserAuth } from '~/composables/user/useUserAuth';
import { navigateTo, useRoute, cartStore as store, wishStore as wStore, userStore as uStore } from '#imports';
import { useOrder } from '~/composables/order/useOrder';

import { useUserInfo } from '~/composables/user/useUserInfo';
const cartStore = store();
const wishStore = wStore();
const userStore = uStore();

const { logOut } = useUserAuth();
const { orderAmount } = useOrder();
const { currentUser } = useUserAuth();
const logOutHandler = async () => {
  const res = await logOut();
  cartStore.setCartStore(null);
  wishStore.changeWishList(null);
  userStore.setCurrentAddress(null);

  if (res && res?.message === 'Success') {
    navigateTo('/');
  }
};
</script>
