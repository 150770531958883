<template>
  <main class="grid-container grow auto-rows-min pt-5 bg-#F7F9F2">
    <ClientOnly fallback="Check...">
      <template v-if="isLoggedIn">
        <Breadcrumbs
          class="mx-auto max-lg:hidden"
          :breadcrumbs="breadcrumbs"
        />
        <div class="grid grid-cols-1 lg:grid-cols-[232px_1fr] gap-4 lg:mt-7">
          <ProfileSidebar class="max-lg:hidden -mt-3" />

          <div class="lg:rounded-8 lg:ring-1 lg:ring-inset lg:ring-#f1f1f1 lg:shadow-[0_6px_18px_0] lg:shadow-black/10 lg:p-11">
            <slot />
          </div>
        </div>
      </template>
      <div v-else>Check...</div>
    </ClientOnly>
  </main>
</template>

<script setup lang="ts">
import type { BreadcrumbsType } from '~/types/commons';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { useOrder } from '~/composables/order/useOrder';

const route = useRoute();
const breadcrumbs = computed(() => (route.meta?.breadcrumbs as BreadcrumbsType[]) ?? []);
const { isLoggedIn } = useUserAuth();
const { userOrdersList, getOrders } = useOrder();

onMounted(async () => {
  if (!userOrdersList.value) {
    await getOrders().catch((err) => {
      console.log(err.data?.message);
    });
  }
});
</script>
